import "./style/index.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthProvider } from "./contexts/auth";
import { Toaster } from "react-hot-toast";
import { BlogProvider } from "./contexts/blogs";
import { MediaProvider } from "./contexts/media";
import TopNav from "./UI/TopNav";

ReactDOM.render(
  <AuthProvider>
    <BlogProvider>
      <MediaProvider>
        <Toaster />
        {/* <TopNav /> */}
        <App />
      </MediaProvider>
    </BlogProvider>
  </AuthProvider>,
  document.getElementById("menu")
);
