import { useState, useEffect, createContext } from "react";

import axios from "axios";
import { API } from "../config/API";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    token: "",
  });

  if (process.server) {
    axios.defaults.baseURL = API;
    axios.defaults.headers.common["Authorization"] = `Bearer ${auth?.token}`;
  } else {
    axios.defaults.baseURL = API;
    axios.defaults.headers.common["Authorization"] = `Bearer ${auth?.token}`;
  }

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      setAuth(JSON.parse(localStorage.getItem("auth")));
    }
  }, []);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
