import { CloseCircleOutlined, InboxOutlined } from "@ant-design/icons";
import { Upload, message, Image, Badge } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { toast } from "react-hot-toast";
import { AuthContext } from "../contexts/auth";
import { MediaContext } from "../contexts/media";
import { API } from "../config/API";

const { Dragger } = Upload;

const MediaLibrary = ({ PreviewShow = false, page = "Media" }) => {
  const [auth, setAuth] = useContext(AuthContext);
  const [media, setMedia] = useContext(MediaContext);
  const router = useHistory();

  const fetchMedia = async () => {
    try {
      const { data } = await axios.get("/media");
      setMedia((prev) => ({ ...prev, images: data }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMedia();
  }, []);

  const props = {
    name: "file",
    multiple: true,
    action: `${API}/upload-image-file`,
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setMedia({
          images: [...media.images, info.file.response],
          selected: info.file.response,
          showMediaModal: false,
        });
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleImageDelete = async (imageID) => {
    try {
      const { data } = await axios.delete(`/media/${imageID}`);
      if (data.ok) {
        setMedia({
          ...media,
          images: media.images.filter((image) => image._id !== imageID),
          selected: null,
        });
        toast.error("Image Deleted");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Dragger {...props} accept="image/*">
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
      <div>
        {page !== "NotMedia" &&
          media?.images?.map((image) => (
            <Badge>
              <Image
                preview={PreviewShow}
                src={image.url}
                style={{
                  paddingTop: 5,
                  paddingRight: 10,
                  height: "100px",
                  width: "100px",
                  objectFit: "cover",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => setMedia({ ...media, selected: image })}
              />
              <br />
              <CloseCircleOutlined
                style={{ color: "red", marginTop: "5px" }}
                onClick={() => handleImageDelete(image._id)}
              />
            </Badge>
          ))}
      </div>
    </>
  );
};

export default MediaLibrary;
