import React, { useState, useEffect, useContext } from "react";
import { Menu, Layout } from "antd";
import { Link } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";
import { AuthContext } from "../../contexts/auth";
import {
  PushpinOutlined,
  CameraOutlined,
  UserSwitchOutlined,
  SettingOutlined,
  UserOutlined,
  MailOutlined,
  MessageOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;
const { Sider } = Layout;

const AuthorNav = () => {
  // context
  const [auth, setAuth] = useContext(AuthContext);
  // state
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState("");
  // hooks
  const onlyWidth = useWindowWidth();

  useEffect(() => {
    process.browser && setCurrent(window.location.pathname);
  }, [process.browser && window.location.pathname]);

  useEffect(() => {
    if (onlyWidth < 800) {
      setCollapsed(true);
    } else if (onlyWidth > 800) {
      setCollapsed(false);
    }
  }, [onlyWidth < 800]);

  const activeName = (name) => `${current === name && "active"}`;

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
    >
      <Menu
        // defaultSelectedKeys={["1"]}
        defaultOpenKeys={["2", "6", "10"]}
        mode="inline"
        inlineCollapsed={collapsed}
      >
        <Menu.Item key="1" icon={<SettingOutlined />}>
          <Link to="/author">
            <a className={activeName("/author")}>Dashboard</a>
          </Link>
        </Menu.Item>

        {/* posts */}
        <SubMenu key="2" icon={<PushpinOutlined />} title="Blogs">
          <Menu.Item key="3">
            <Link to="/author/blogs" className={activeName("/author/blogs")}>
              Blogs
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link
              to="/author/blog/new"
              className={activeName("/admin/blog/new")}
            >
              Add Blogs
            </Link>
          </Menu.Item>
          <Menu.Item key="5">
            <Link
              to="/author/categories"
              className={activeName("/admin/categories")}
            >
              Categories
            </Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="14" icon={<MessageOutlined />}>
          <Link to="/messages" className={activeName("/messages")}>
            Messages
          </Link>
        </Menu.Item>

        <Menu.Item key="15" icon={<MailOutlined />}>
          <Link to="/emails" className={activeName("/emails")}>
            Emails
          </Link>
        </Menu.Item>

        {/* library */}
        <SubMenu key="6" icon={<CameraOutlined />} title="Media">
          <Menu.Item key="7">
            <Link
              to="/author/media/library"
              className={activeName("/admin/media/library")}
            >
              Library
            </Link>
          </Menu.Item>
          <Menu.Item key="8">
            <Link
              to="/author/media/new"
              className={activeName("/admin/media/new")}
            >
              Add New
            </Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="13" icon={<UserOutlined />}>
          <Link
            to={`/author/${auth?.user?._id}`}
            className={activeName(`/author/${auth?.user?._id}`)}
          >
            Profile
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default AuthorNav;
