import { useState, useEffect, useContext } from "react";
import { Card, Layout } from "antd";
import AdminLayout from "../../UI/AdminLayout";
import { Form, Input, Row, Col, Button, List, Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-hot-toast";
import CategoryUpdateModal from "../../components/CategoryUpdateModel";
import { BlogContext } from "../../contexts/blogs";

function Categories() {
  // state
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useContext(BlogContext);

  // update state
  const [updatingCategory, setUpdatingCategory] = useState({});
  const [visible, setVisible] = useState(false);
  // hooks
  const [form] = Form.useForm();
  const { categories } = post;
  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const { data } = await axios.get("/categories");
      setPost((prev) => ({ ...prev, categories: data }));
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = async (values) => {
    // console.log("values => ", values);
    try {
      setLoading(true);
      const { data } = await axios.post("/category", values);
      setPost((prev) => ({ ...prev, categories: [data, ...categories] }));
      // console.log(data);
      toast.success("Category created successfully");
      setLoading(false);
      form.resetFields(["name"]);
    } catch (err) {
      console.log(err);
      toast.error("Category create failed");
      setLoading(false);
    }
  };

  const handleDelete = async (item) => {
    try {
      const { data } = await axios.delete(`/category/${item.slug}`);
      setPost((prev) => ({
        ...prev,
        categories: categories.filter((cat) => cat._id !== data._id),
      }));
      toast.success("Category deleted successfully");
    } catch (err) {
      console.log(err);
      toast.error("Category delete failed");
    }
  };

  const handleEdit = async (item) => {
    setUpdatingCategory(item);
    setVisible(true);
  };

  const handleUpdate = async (values) => {
    try {
      const { data } = await axios.put(
        `/category/${updatingCategory.slug}`,
        values
      );
      const newCategories = categories.map((cat) => {
        if (cat._id === data._id) {
          return data;
        }
        return cat;
      });
      setPost((prev) => ({ ...prev, categories: newCategories }));
      toast.success("Categrory updated successfully");
      setVisible(false);
      setUpdatingCategory({});
    } catch (err) {
      console.log(err);
      toast.error("Category update failed");
    }
  };

  return (
    <AdminLayout>
      <Row>
        {/* first column */}
        <Col xs={22} sm={22} lg={10} offset={1}>
          <Card>
            <h1>Categories</h1>
            <p>Add new category</p>

            <Form onFinish={onFinish} form={form}>
              <Form.Item name="name">
                <Input
                  prefix={<EditOutlined className="site-form-item-icon" />}
                  placeholder="Give it a name"
                />
              </Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
        {/* second column */}
        <Col xs={22} sm={22} lg={10} offset={1}>
          <Card>
            <List
              itemLayout="horizontal"
              dataSource={categories}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <a onClick={() => handleEdit(item)}>edit</a>,
                    <a onClick={() => handleDelete(item)}>delete</a>,
                  ]}
                >
                  <List.Item.Meta title={item.name} />
                </List.Item>
              )}
            ></List>
          </Card>
        </Col>

        <CategoryUpdateModal
          visible={visible}
          setVisible={setVisible}
          handleUpdate={handleUpdate}
          updatingCategory={updatingCategory}
        />
      </Row>
    </AdminLayout>
  );
}

export default Categories;
