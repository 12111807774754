import { Row, Col } from "antd";

import UploadFile from "../../components/UploadFile";
import AuthorLayout from "../../UI/AuthorLayout";

function AuthorUploadMedia() {
  return (
    <AuthorLayout>
      <Row>
        <Col span={24}>
          <div
            style={{
              padding: 100,
              textAlign: "center",
            }}
          >
            <UploadFile redirectToLibrary={true} />
          </div>
        </Col>
      </Row>
    </AuthorLayout>
  );
}

export default AuthorUploadMedia;
