import { Col, Row } from "antd";
import React from "react";
import MediaLibrary from "../../components/MediaLibrary";
import AuthorLayout from "../../UI/AuthorLayout";

const AuthorMedia = () => {
  return (
    <AuthorLayout>
      <Row>
        <Col span={24}>
          <MediaLibrary PreviewShow={true} />
        </Col>
      </Row>
    </AuthorLayout>
  );
};

export default AuthorMedia;
