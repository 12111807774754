import { AuthContext } from "../contexts/auth";
import { useContext, useEffect, useState } from "react";
import { Layout } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import AuthorNav from "./nav/AuthorNav";

const { Content } = Layout;

function AuthorLayout({ children }) {
  const [auth, setAuth] = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const router = useHistory();

  useEffect(() => {
    getCurrentAdmin();
  }, [auth?.token]);

  const getCurrentAdmin = async () => {
    try {
      const { data } = await axios.get("/current-author");
      setLoading(false);
    } catch (err) {
      console.log(err);
      router.push("/login");
    }
  };

  // if (loading) {
  //   return <>loading...</>;
  // }

  return (
    <Layout>
      <AuthorNav />
      <Layout>
        {loading ? (
          <>loading....</>
        ) : (
          <Content style={{ padding: "10px" }}>{children}</Content>
        )}
      </Layout>
    </Layout>
  );
}

export default AuthorLayout;
