import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import AuthorLayout from "../../UI/AuthorLayout";

const AuthorDashboard = () => {
  const [auth] = useContext(AuthContext);
  const router = useHistory();

  useEffect(() => {
    if (!auth?.token) {
      router.push("/login");
    }
  }, [auth]);

  return (
    <AuthorLayout>
      <h2>Comming Soon </h2>
    </AuthorLayout>
  );
};

export default AuthorDashboard;
