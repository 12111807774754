import { Col, Row } from "antd";
import React from "react";
import MediaLibrary from "../../components/MediaLibrary";
import AdminLayout from "../../UI/AdminLayout";

const Media = () => {
  return (
    <AdminLayout>
      <Row>
        <Col span={24}>
          <MediaLibrary PreviewShow={true} />
        </Col>
      </Row>
    </AdminLayout>
  );
};

export default Media;
