import { Row, Col } from "antd";

import UploadFile from "../../components/UploadFile";
import AdminLayout from "../../UI/AdminLayout";

function UploadMedia() {
  return (
    <AdminLayout>
      <Row>
        <Col span={24}>
          <div
            style={{
              padding: 100,
              textAlign: "center",
            }}
          >
            <UploadFile redirectToLibrary={true} />
          </div>
        </Col>
      </Row>
    </AdminLayout>
  );
}

export default UploadMedia;
