import { List } from "antd";
import React from "react";

const BlogList = ({ blogs, handleDelete, handleEdit }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={blogs}
      renderItem={(item) => (
        <List.Item
          actions={[
            <a onClick={() => handleEdit(item)}>edit</a>,
            <a onClick={() => handleDelete(item)}>delete</a>,
          ]}
        >
          <List.Item.Meta title={item.title} />
        </List.Item>
      )}
    />
  );
};
export default BlogList;
