import { useEffect, useState, useContext } from "react";
import { Row, Col, Button, List, Input } from "antd";
import AdminLayout from "../../UI/AdminLayout";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { BlogContext } from "../../contexts/blogs";
import { useHistory } from "react-router-dom";
import BlogList from "../../components/BlogList";
// import PostsList from "../../../components/posts/PostsList";

const Blogs = () => {
  const [blog, setBlog] = useContext(BlogContext);
  const [keyword, setKeyword] = useState("");
  // hook
  const router = useHistory();

  const { blogs } = blog;

  useEffect(() => {
    fetfchBlogs();
  }, []);

  const fetfchBlogs = async () => {
    try {
      const { data } = await axios.get("/posts");
      setBlog((prev) => ({ ...prev, blogs: data }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = async (blog) => {
    // console.log("EDIT POST", post);
    return router.push(`/admin/blog/${blog.slug}`);
  };

  const handleDelete = async (blog) => {
    // console.log("DELETE POST", post);
    try {
      const answer = window.confirm("Are you sure you want to delete?");
      if (!answer) return;
      const { data } = await axios.delete(`/post/${blog._id}`);
      if (data.ok) {
        setBlog((prev) => ({
          ...prev,
          blogs: prev.blogs.filter((p) => p._id !== blog._id),
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AdminLayout>
      <Row>
        <Col span={14} offset={1}>
          <Button type="primary">
            <Link to="/admin/blog/new">
              <PlusOutlined /> Add New
            </Link>
          </Button>
          <h1 style={{ marginTop: 15 }}>{blogs?.length} blogs</h1>
          <Input
            placeholder="Search"
            type="search"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value.toLowerCase())}
          />
          <BlogList
            blogs={blogs?.filter((p) =>
              p.title.toLowerCase().includes(keyword)
            )}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </Col>
      </Row>
    </AdminLayout>
  );
};

export default Blogs;
