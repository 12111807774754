import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./pages/common/Login";
import Signup from "./pages/common/Signup";
import NotFound from "./pages/common/NotFound";

import Dashboard from "./pages/common/Dashboard";
import Blogs from "./pages/admin/Blogs";
import AddBlogs from "./pages/admin/AddBlogs";
import EditBlogs from "./pages/admin/EditBlogs";
import Categories from "./pages/admin/Catagories";
import UploadMedia from "./pages/admin/UploadMedia";
import Media from "./pages/admin/Media";
import AllUsers from "./pages/admin/AllUsers";
import UserUpdate from "./pages/admin/UserUpdate";
import AddNewUser from "./pages/admin/AddNewUser";
import MyProfile from "./pages/admin/MyProfile";
import Messages from "./pages/common/Messages";
import Emails from "./pages/common/Emails";
import TopNav from "./UI/TopNav";

import AuthorBlogs from "./pages/author/AuthorBlogs";
import AuthorAddBlogs from "./pages/author/AuthorAddBlogs";
import AuthorCategories from "./pages/author/AuthorCategories";
import AuthorDashboard from "./pages/author/AuthorDashboard";
import AuthorEditBlogs from "./pages/author/AuthorEditBlogs";
import AuthorMedia from "./pages/author/AuthorMedia";
import AuthorMyProfile from "./pages/author/AuthorMyProfile";
import AuthorUploadMedia from "./pages/author/AuthorUploadMedia";
import CommonDashboard from "./pages/common/CommonDashboard";

const App = () => {
  return (
    <BrowserRouter>
      <TopNav />
      <Switch>
        <Route exact path={"/"} component={CommonDashboard} />
        <Route exact path={"/admin"} component={Dashboard} />
        <Route exact path={"/login"} component={Login} />
        <Route exact path={"/signup"} component={Signup} />
        <Route exact path={"/messages"} component={Messages} />
        <Route exact path={"/emails"} component={Emails} />

        {/* admins */}
        <Route exact path={"/admin/blogs"} component={Blogs} />
        <Route exact path={"/admin/blog/new"} component={AddBlogs} />
        <Route exact path={"/admin/blog/:slug"} component={EditBlogs} />
        <Route exact path={"/admin/categories"} component={Categories} />
        <Route exact path={"/admin/media/new"} component={UploadMedia} />
        <Route exact path={"/admin/media/library"} component={Media} />
        <Route exact path={"/admin/users"} component={AllUsers} />
        <Route exact path={"/admin/users/:id"} component={UserUpdate} />
        <Route exact path={"/admin/add/user"} component={AddNewUser} />
        <Route exact path={"/admin/:id"} component={MyProfile} />

        {/* authors */}
        <Route exact path={"/author"} component={AuthorDashboard} />
        <Route exact path={"/author/blogs"} component={AuthorBlogs} />
        <Route exact path={"/author/blog/new"} component={AuthorAddBlogs} />
        <Route exact path={"/author/blog/:slug"} component={AuthorEditBlogs} />
        <Route exact path={"/author/categories"} component={AuthorCategories} />
        <Route exact path={"/author/media/new"} component={AuthorUploadMedia} />
        <Route exact path={"/author/media/library"} component={AuthorMedia} />
        <Route exact path={"/author/:id"} component={AuthorMyProfile} />

        <Route exact path={"*"} component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
