import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import AdminLayout from "../../UI/AdminLayout";

const Dashboard = () => {
  const [auth] = useContext(AuthContext);
  const router = useHistory();

  useEffect(() => {
    if (!auth?.token) {
      router.push("/login");
    }
  }, [auth]);

  return (
    <AdminLayout>
      <h2>Comming Soon </h2>
    </AdminLayout>
  );
};

export default Dashboard;
